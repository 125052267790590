import {Box, Typography} from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FaxIcon from '@mui/icons-material/Fax';
import logo from '../assets/logo/as_logo_small.png';
import Link from "@mui/material/Link";

export default function ContactUs() {

    return <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
        <Typography variant="caption" sx={{color: '#939393', display: 'flex', alignItems: 'center'}}>
            <img src={logo} alt='as' style={{height: 16, opacity: 0.6, marginRight: 4}}/>
        </Typography>
        <Typography variant="caption" sx={{color: '#939393', display: 'flex', alignItems: 'center'}}>
            <BusinessIcon fontSize="small" sx={{mr: 0.5, ml: 1}}/> 910 Auburn Ct, Fremont, CA 94538
        </Typography>
        <Typography variant="caption" sx={{color: '#939393', display: 'flex', alignItems: 'center'}}>
            <PhoneIcon fontSize="small"/> +1(408) 263-0988
            <FaxIcon fontSize="small" sx={{mr: 0.5, ml: 1}}/> +1(408) 217-1960
            <EmailIcon fontSize="small" sx={{mr: 0.5, ml: 1}}/> support@leopardimaging.com
        </Typography>
    </Box>
}