import React, {useEffect, useRef, useState} from 'react';
import {svg} from "leaflet/src/layer";
import {convertRealPointsToViewbox, convertViewboxPointsToReal, isAboveTheBorder} from "./helper";

const Point = ({point, onMouseDown}) => {
    return (
        <rect
            // ref={ref}
            x={point.x - 10}
            y={point.y - 10}
            onMouseDown={onMouseDown}
            onTouchStart={onMouseDown}
            width="20px"
            height="20"
            fill="rgba(0, 0, 0, 0)"
            stroke="white"
            strokeWidth="1.25"
            vectorEffect="non-scaling-stroke"
            style={{cursor: 'pointer'}}
        />
    )
}

const Line = ({start, end, onMouseDown}) => (
    <line
        x1={start.x}
        y1={start.y}
        x2={end.x}
        y2={end.y}
        fill="rgba(0,0,0,0)"
        stroke="white"
        strokeWidth="1.5"
        shapeRendering="geometricPrecision"
        strokeDasharray="3"
        strokeDashoffset="0"
        vectorEffect="non-scaling-stroke"
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        style={{cursor: 'move'}}
    >
        <animate
            attributeName="stroke-dashoffset"
            values="0;1000;0"
            dur="100s"
            repeatCount="indefinite"
        />
    </line>
)


export default function LineSelect({src, alt, value, onChange, onFinish, imageStyle = {}, style = {}}) {
    const imageRef = useRef(null);
    const svgRef = useRef(null);
    const [draggingInfo, setDraggingInfo] = useState({});
    const [mouseLocation, setMouseLocation] = useState(null);
    const [isDarkened, setIsDarkened] = useState(false);
    const [offset, setOffset] = useState({x: 0, y: 0});
    const [viewBoxPoints, setViewBoxPoints] = useState([]);
    const [ratio, setRatio] = useState({x: 1, y: 1});

    useEffect(() => {
        const updateRatio = () => {
            if (imageRef.current) {
                setRatio({
                    x: imageRef.current.clientWidth / imageRef.current.naturalWidth,
                    y: imageRef.current.clientHeight / imageRef.current.naturalHeight
                });
            }
            if (svgRef.current) {
                setViewBoxPoints(convertRealPointsToViewbox(value, svgRef.current, {
                    x: imageRef.current.clientWidth / imageRef.current.naturalWidth,
                    y: imageRef.current.clientHeight / imageRef.current.naturalHeight
                }));
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateRatio();
        });

        if (imageRef.current) {
            resizeObserver.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                resizeObserver.unobserve(imageRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (Object.keys(draggingInfo).length !== 0) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [draggingInfo]);

    useEffect(() => {
        if (svgRef.current) {
            onChange(convertViewboxPointsToReal(viewBoxPoints, svgRef.current, ratio));
        }
    }, [viewBoxPoints]);


    useEffect(() => {
        if (viewBoxPoints && viewBoxPoints.length > 2) {
            setViewBoxPoints([]);
        }

        if (viewBoxPoints && viewBoxPoints.length === 2) {
            setIsDarkened(true);
            onFinish();
        }
    }, [viewBoxPoints?.length]);


    const getEventClientCoords = (event) => {
        if (event.touches && event.touches[0]) {
            return {x: event.touches[0].clientX, y: event.touches[0].clientY};
        } else {
            return {x: event.clientX, y: event.clientY};
        }
    }

    const handleMouseDown = (event, index) => {
        const svg = svgRef.current;
        const rect = svg.getBoundingClientRect();
        const eventCoords = getEventClientCoords(event);
        const x = eventCoords.x - rect.x;
        const y = eventCoords.y - rect.y;

        if (typeof index === 'number') {
            setDraggingInfo({type: 'point', index: index});
        } else {
            const viewboxClick = convertRealPointsToViewbox([{x, y}], svg)[0];
            const centerX = (viewBoxPoints[0].x + viewBoxPoints[1].x) / 2;
            const centerY = (viewBoxPoints[0].y + viewBoxPoints[1].y) / 2;
            setOffset({x: viewboxClick.x - centerX, y: viewboxClick.y - centerY});
            setDraggingInfo({type: 'line'});
        }
    };


    const handleMouseMove = (event) => {
        const svg = svgRef.current;
        const rect = svg.getBoundingClientRect();
        const eventCoords = getEventClientCoords(event);
        const x = eventCoords.x - rect.x;
        const y = eventCoords.y - rect.y;
        const viewBoxLoc = convertRealPointsToViewbox([{x, y}], svg)[0];

        if (Object.keys(draggingInfo).length === 0 && !event.touches) {
            setMouseLocation(convertRealPointsToViewbox([{x, y}], svg)[0]);
        } else if (draggingInfo.type === 'point') {
            if (!isAboveTheBorder(viewBoxLoc, svg)) {
                const newPoints = [...viewBoxPoints];
                newPoints[draggingInfo.index] = viewBoxLoc;
                setViewBoxPoints(newPoints);
            }
        } else if (draggingInfo.type === 'line') {
            const deltaX = viewBoxLoc.x - offset.x - (viewBoxPoints[0].x + viewBoxPoints[1].x) / 2;
            const deltaY = viewBoxLoc.y - offset.y - (viewBoxPoints[0].y + viewBoxPoints[1].y) / 2;
            const newPoints = [
                {x: viewBoxPoints[0].x + deltaX, y: viewBoxPoints[0].y + deltaY},
                {x: viewBoxPoints[1].x + deltaX, y: viewBoxPoints[1].y + deltaY},
            ]
            if (!isAboveTheBorder(newPoints[0], svg) && !isAboveTheBorder(newPoints[1], svg)) {
                setViewBoxPoints(newPoints);
            }
        }
    };

    const handleMouseUp = (event) => {
        if (event.touches && event.touches[0]) {
            event.preventDefault();
        }
        if (event.touches && event.touches.length === 0) {
            if (Object.keys(draggingInfo).length !== 0) {
                setDraggingInfo({});
            }
            return;
        }
        setIsDarkened(true);
        if (Object.keys(draggingInfo).length !== 0) {
            setDraggingInfo({});
            return;
        }

        if (viewBoxPoints.length >= 2) {
            setViewBoxPoints([]);
            setIsDarkened(false);
            return;
        }

        const svg = svgRef.current;
        const rect = svg.getBoundingClientRect();
        const eventCoords = getEventClientCoords(event);
        const x = eventCoords.x - rect.x;
        const y = eventCoords.y - rect.y;

        setViewBoxPoints((prevPoints) => [...prevPoints, ...convertRealPointsToViewbox([{x, y}], svgRef.current)]);
    };

    return <div
        style={{
            display: 'inline-block',
            position: 'relative',
            margin: '0',
            padding: '0',
            fontSize: '0',
            ...style
        }}
    >
        <img
            key={`${alt}-image`}
            ref={imageRef}
            src={src}
            alt={alt}
            style={{...imageStyle, filter: isDarkened ? 'brightness(50%)' : 'none'}}
        />
        <svg
            ref={svgRef}
            key='image-svg'
            onMouseMove={handleMouseMove}
            onTouchMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchEnd={handleMouseUp}
            viewBox='0 0 1000 1000'
            style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                userSelect: 'none',
                touchAction: 'none'
            }}
        >
            {viewBoxPoints.length === 2 &&
                <>
                    <line
                        x1={viewBoxPoints[0].x}
                        y1={viewBoxPoints[0].y}
                        x2={viewBoxPoints[1].x}
                        y2={viewBoxPoints[1].y}
                        stroke="transparent"
                        strokeWidth="15"
                        onMouseDown={handleMouseDown}
                        onTouchStart={handleMouseDown}
                        style={{cursor: 'move'}}
                        key='click-line'
                    />
                    <Line
                        start={viewBoxPoints[0]}
                        end={viewBoxPoints[1]}
                        onMouseDown={handleMouseDown}
                        key='line'
                    />
                </>
            }
            {(viewBoxPoints.length === 1 && mouseLocation) &&
                <Line start={viewBoxPoints[0]} end={mouseLocation}/>}

            {viewBoxPoints.map((point, index) => (
                <>
                    <circle
                        key={`${index}-click-point`}
                        cx={point.x}
                        cy={point.y}
                        r="40"
                        fill="transparent"
                        stroke="none"
                        pointerEvents="all"
                        onMouseDown={(event) => handleMouseDown(event, index)}
                    />
                    <Point
                        key={`${index}-point`}
                        point={point}
                        onMouseDown={(event) => handleMouseDown(event, index)}
                    />
                </>
            ))}
        </svg>
    </div>
}
