import Cookies from "js-cookie";
import {useLocation, useNavigate} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {routes, useGetDataCreateAsset} from "../routes/api";
import {LoadingComponent} from "../util/loading";
import Grid from "@mui/material/Grid";
import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import PopupDialog from "./popupDialog";
import {relationContent} from "../util/util";


export default function RelationView({alias, handleChange, relationName, currentValue, handleNew}) {
    const token = Cookies.get('token');
    const canCreate = Cookies.get('can_create') === 'true';
    const route = routes[alias];

    const {data: content, loading, handleCall} = useGetDataCreateAsset(token, route);
    const [openStates, setOpenStates] = useState({});
    const [openDialogs, setOpenDialogs] = useState({});
    const [relations, setRelations] = useState({});

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from ? location.state.from : {pathname: `/`};

    const fetchData = async () => {
        try {
            // setLoading(true);
            await handleCall();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!loading) {
            setOpenStates(Object.entries(content).reduce((acc, item, index) => ({...acc, [index]: false}), {}));
            setOpenDialogs(Object.entries(content).reduce((acc, item, index) => ({...acc, [index]: false}), {}));
            if (relationName) {
                setRelations(relationName.reduce((result, key) => {
                    if (content[key]) {
                        result[key] = content[key];
                    }
                    return result;
                }, {}));
            } else {
                setRelations(content);
            }
            // setLoading(false);
        }
    }, [content]);

    const handleOpen = (key) => {
        setOpenStates((prevOpenStates) => ({...prevOpenStates, [key]: true}));
    };

    const handleClose = (key) => {
        setOpenStates((prevOpenStates) => ({...prevOpenStates, [key]: false}));
    };

    const handleRelationRefresh = async (key) => {
        setOpenDialogs({...openDialogs, [key]: false});
        await handleCall();
    }

    const handleAddButton = (key) => {
        setOpenDialogs({...openDialogs, [key]: true})
    }

    const handleDialogClose = (key) => {
        setOpenDialogs({...openDialogs, [key]: false});
    }

    const getValue = (options, key, multiple) => {
        let value;
        if (!currentValue[key]) {
            value = multiple ? [] : null;
        } else if (multiple) {
            value = currentValue[key].map(item => options.find(option => option[0] === item)).filter(item => item);
        } else {
            value = options.find(option => option[0] === currentValue[key]) || null;
        }
        return value;
    }

    if (!canCreate) return navigate(from);
    if (loading) return <LoadingComponent/>

    return <Grid container
                 spacing={2}
                 alignItems="center"
                 sx={{mb: 5}}
    >
        {Object.entries(relations).filter(([_, value]) => value).map(([key, value], index) => (
            <Fragment key={`${key}-group`}>
                <Grid item xs={6} key={`${key}-select`}>
                    <Autocomplete
                        id={key}
                        open={openStates[key] || false}
                        onOpen={() => {
                            handleOpen(key)
                        }}
                        onClose={() => {
                            handleClose(key)
                        }}
                        label={key}
                        name={key}
                        value={getValue(value.data, key, value.multiple)}
                        isOptionEqualToValue={(option, v) => v ? option[0] === v[0] : false}
                        getOptionLabel={(option) => option[1]}
                        getOptionKey={(option) => option[0]}
                        options={value.data}
                        onChange={(e, newValue) => {
                            handleChange(newValue, key, value.multiple, value.limit);
                        }}
                        loading={loading}
                        multiple={value.multiple}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={value.label}
                                helperText={value.limit && `${value.label} limited up to ${value.limit}`}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
                {relationContent[value.type] &&
                    <>
                        <Grid item xs={4} key={`${key}-addNew`}>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<AddIcon/>}
                                onClick={() => handleAddButton(index)}>
                                New
                            </Button>
                        </Grid>
                        <Grid item xs={2} key={`${key}-popup`}>
                            <PopupDialog
                                open={openDialogs[index]}
                                display={value.type}
                                content={relationContent[value.type]((v) => handleNew(v, key, value.multiple, value.limit), () => handleRelationRefresh(index))}
                                handleClose={() => handleDialogClose(index)}
                            /></Grid>

                    </>}
            </Fragment>
        ))}
    </Grid>
}