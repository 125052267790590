import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";

//export const BASE_URL = "https://13.57.214.89:8000";
export const BASE_URL = "https://dm-be.leopardaws.com";

export const routes = {
    unit: '/camera/u',
    unitgroup: '/camera/ug',
    camera: '/camera/c',
    cameragroup: '/camera/cg',
    confirmation: '/camera/c/confirm',
    record: '/record/r',
    organization: '/organization/o',
    aimodel: '/aimodel/am',
    firmware: '/firmware/f',
    account: '/account/a',
    application: '/application/app',
    cameraproperty: '/camera/cp',
    accountproperty: '/account/ap',
    firmwareproperty: '/firmware/fp',
    aimodelproperty: '/aimodel/amp',
    applicationproperty: '/application/apr',
}

function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
}

function useCall(url, headers, method = "GET") {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [statusCode, setStatusCode] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();

    const callHandler = async ({body, params, updatedUrl}) => {
        const finalUrl = updatedUrl ? `${updatedUrl}/` : url;
        const route = params ? `${BASE_URL}${finalUrl}?${objToQueryString(params)}` : `${BASE_URL}${finalUrl}`;
        setLoading(true);

        try {
            const response = await fetch(route, {
                method: method,
                headers: headers,
                body: body
            })
            setStatusCode(response.status);
            if (response.status === 401) {
                // Redirect to login page
                navigate('/login', {state: {from: location}});
            }

            const content = await response.json();
            setData(content);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return {data, loading, error, statusCode, callHandler};
}

export function useLogIn() {
    const {data, loading, error, statusCode, callHandler} = useCall(
        '/api-token-auth/',
        {'Content-Type': 'application/json'},
        'POST'
    )
    const handleCall = async ({body}) => {
        try {
            await callHandler({body: JSON.stringify(body)});
        } catch (error) {
            console.error("Log in error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useVerifyCode() {
    const {data, loading, error, statusCode, callHandler} = useCall(
        '/verify-code/',
        {'Content-Type': 'application/json'},
        'POST'
    )
    const handleCall = async ({body}) => {
        try {
            await callHandler({body: JSON.stringify(body)});
        } catch (error) {
            console.error("Log in error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useLogOut(token) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        '/logout/',
        {'Content-Type': 'application/json', 'Authorization': `Token ${token}`},
        'POST',
    );
    const handleCall = async () => {
        try {
            await callHandler({});
        } catch (error) {
            console.error("Log out error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useGetEnums(token) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `/getenums/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
    );

    const handleCall = async () => {
        try {
            await callHandler({});
        } catch (error) {
            console.error("Get enums error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useGetDataList(token, route) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
    );

    const handleCall = async ({params, updatedUrl}) => {
        try {
            await callHandler({params: params, updatedUrl: updatedUrl});
        } catch (error) {
            console.error("Get data list error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useGetDataCreateAsset(token, route) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/create/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
    );

    const handleCall = async () => {
        try {
            await callHandler({});
        } catch (error) {
            console.error("Get data list error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useGetDataById(token, route, id) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/${id}/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        });
    const handleCall = async () => {
        try {
            await callHandler({});
        } catch (error) {
            console.error("Get data error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}


export function usePostData(token, route) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/`,
        {'Content-Type': 'application/json', 'Authorization': `Token ${token}`},
        'POST'
    );
    const handleCall = async ({body}) => {
        try {
            await callHandler({body: JSON.stringify(body)});
        } catch (error) {
            console.error("Post new error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function usePutData(token, route, id) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/${id}/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
        'PUT'
    );
    const handleCall = async ({body}) => {
        try {
            await callHandler({body: JSON.stringify(body)});
        } catch (error) {
            console.error("Put update error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}


export function usePatchData(token, route, id) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/${id}/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
        'PATCH'
    );
    const handleCall = async ({body}) => {
        try {
            await callHandler({body: JSON.stringify(body)});
        } catch (error) {
            console.error("Patch update error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useDeleteData(token, route, id) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/${id}/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
        'DELETE',
    );
    const handleCall = async () => {
        try {
            await callHandler({});
        } catch (error) {
            console.error("Delete error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useBulkDeleteData(token, route) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/bulk_delete/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
        'DELETE',
    );
    const handleCall = async (body) => {
        try {
            await callHandler({body: JSON.stringify(body)});
        } catch (error) {
            console.error("Bulk delete error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useSearch(token, route) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
        'GET',
    );
    const handleCall = async ({params}) => {
        try {
            await callHandler({params: params});
        } catch (error) {
            console.error("Search error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function usePostFile(token, route) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/`,
        {'Authorization': `Token ${token}`},
        'POST'
    );
    const handleCall = async ({body}) => {
        try {
            await callHandler({body: body});
        } catch (error) {
            console.error("Post new file error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function usePatchFile(token, route, id) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/${id}/`,
        {
            'Authorization': `Token ${token}`,
        },
        'PATCH'
    );
    const handleCall = async ({body}) => {
        try {
            await callHandler({body: body});
        } catch (error) {
            console.error("Patch update file error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useRefreshData(token, route, id) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/${id}/fetch/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
        "POST");
    const handleCall = async ({body}) => {
        try {
            await callHandler({body: JSON.stringify(body)});
        } catch (error) {
            console.error("Get refresh data error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export function useGroupUpdateData(token, route) {
    const {data, loading, error, statusCode, callHandler} = useCall(
        `${route}/`,
        {
            'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
        },
        "POST");
    const handleCall = async ({body}) => {
        try {
            await callHandler({body: JSON.stringify(body)});
        } catch (error) {
            console.error("Get group update data error:", error.message);
            throw error;
        }
    };
    return {data, loading, error, statusCode, handleCall};
}

export const fileUrl = (path) => `${BASE_URL}${path}`
