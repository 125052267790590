import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {FormControlLabel, FormGroup, Grid, Paper, Slider, Switch} from "@mui/material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {timestampToTimeString} from "../util/util";

const unitToTime = {
    week: 1000 * 60 * 60 * 24 * 7,
    day: 1000 * 60 * 60 * 24,
    hour: 1000 * 60 * 60,
    minute: 1000 * 60,
    second: 1000,
}


const filterTime = (obj, timeDiff, unit) => {
    if (obj.time) {
        const now = new Date();
        const timestamp = new Date(obj.time);
        return (now - timestamp) / unitToTime[unit] <= timeDiff
    }
    return false
}

function stringToHexColor(str, rgb = false, alpha = 0.08) {
    let hash = 0x811c9dc5; // 32-bit FNV-1a hash initialization
    for (let i = 0; i < str.length; i++) {
        hash ^= str.charCodeAt(i);
        hash = (hash * 0x01000193) >>> 0; // Multiply by FNV prime and ensure it's a 32-bit number
    }

    let hexColor = '';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        hexColor += ('00' + value.toString(16)).slice(-2);
    }
    if (rgb) {
        let bigint = parseInt(hexColor, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `#${hexColor}`;
}

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{p: 1}}>
                <Typography variant='body2' key='datetime' gutterBottom>{payload[0].payload.datetime}</Typography>
                {payload.map(item => <Typography variant='body2'
                                                 key={item.name}>{item.name} : {item.value}</Typography>)}
            </Paper>
        );
    }

    return null;
};

export const itronLabels = {
    incar: 'Car Incoming',
    inbus: 'Bus Incoming',
    inped: 'Pedestrian Incoming',
    incycle: 'Bicycle Incoming',
    intruck: 'Truck Incoming',
    outcar: 'Car Outgoing',
    outbus: 'Bus Outgoing',
    outped: 'Pedestrian Outgoing',
    outcycle: 'Bicycle Outgoing',
    outtruck: 'Truck Outgoing',
}

export function RenderChart({data, timeRange, setTimeRange}) {
    const [states, setStates] = useState(Object.keys(data[0].results).reduce((acc, key) => ({
        ...acc,
        [itronLabels[key]]: true
    }), {}));
    const dataKey = Object.keys(data[0].results).map(key => itronLabels[key]);
    const minDate = new Date(data[0].datetime).getTime() < new Date().getTime() - 24 * 60 * 60 * 1000 ? new Date().getTime() - 24 * 60 * 60 * 1000 : new Date(data[0].datetime).getTime();

    const [viewData, setViewData] = useState([]);

    useEffect(() => {
        const allData = data.map(datum => (Object.entries(datum.results).reduce((acc, [key, value], _) => ({
            ...acc,
            [itronLabels[key]]: value
        }), {time: datum.time, datetime: datum.datetime})));
        setViewData(allData.filter(datum => new Date(datum.datetime).getTime() >= timeRange[0] && new Date(datum.datetime).getTime() <= timeRange[1]));
    }, [data, timeRange]);

    const handleSelectionChange = (event) => {
        setStates({
            ...states,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSliderChange = (event, newValue) => {
        setTimeRange(newValue);
    };

    return <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item alignItems="center">
            <Typography variant="overline">Time Range</Typography>
        </Grid>
        <Grid item xs={6}>
            <Slider
                value={timeRange}
                onChange={handleSliderChange}
                min={minDate}
                max={new Date(data[data.length - 1].datetime).getTime()}
                aria-labelledby="date-slider"
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => (timestampToTimeString(value, true).datetime)}
            />
        </Grid>
        <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <FormGroup row>
                {dataKey.map((key) => (
                    <FormControlLabel
                        control={<Switch/>}
                        checked={states[key]}
                        onChange={handleSelectionChange}
                        name={key}
                        label={key}
                        key={key}
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: stringToHexColor(key),
                                '&:hover': {
                                    backgroundColor: stringToHexColor(key, true),
                                },
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: stringToHexColor(key),
                            },
                        }}
                    />
                ))}
            </FormGroup>
        </Grid>
        <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <ResponsiveContainer width="100%" minHeight={400}>
                <LineChart data={viewData} minWidth={450} minHeight={300}>
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3"/>
                    <XAxis dataKey="time" padding={{left: 10, right: 10}}/>
                    <YAxis/>
                    <Tooltip content={<CustomTooltip/>}/>
                    {dataKey.map((dataKey) => (
                        <Line key={dataKey} name={dataKey} type="monotone" dataKey={dataKey}
                              stroke={stringToHexColor(dataKey)}
                              strokeWidth={2} hide={!states[dataKey]} connectNulls/>
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography variant="overline" gutterBottom>all timestamps are in user timezone</Typography>
        </Grid>
    </Grid>
}