import {IconButton, Snackbar, Typography} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";


export default function WaitTimeStatus({snackbarList, handleClose}) {
    return <>
        {snackbarList.map((item) => (
                <Snackbar
                    key={`${item.field}-${item.model}`}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    open={true}
                    autoHideDuration={10000}
                    onClose={(event, reason) => handleClose(item.id, event, reason)}
                    message={<Typography variant='body2' style={{whiteSpace: 'pre-line'}}>
                        {`${item.field} on ${item.model} is estimated to take ${item.time}.
                    Please refresh the detail page later for further status update if no error is shown.`}
                    </Typography>
                    }
                    action={<IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => handleClose(item.id)}
                    >
                        <CloseIcon fontSize="small"/>
                    </IconButton>}
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            backgroundColor: '#ffffff',
                            color: '#303030',
                            border: '1px solid #ddd',
                        },
                        mt: 5
                    }}
                />
            )
        )}
    </>
}