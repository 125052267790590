import Cookies from "js-cookie";
import {fileUrl, routes, useGetDataById, useRefreshData} from "../routes/api";
import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {LoadingComponent} from "../util/loading";
import {
    Alert,
    AlertTitle,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    FormControlLabel,
    Grid,
    IconButton,
    Switch
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    filterRecords,
    removeBadRecords,
    sleep,
    toHumanReadableTime,
    totalRecord,
    updateConfirmationMessage,
    updateData,
} from "../util/util";
import HideImageIcon from '@mui/icons-material/HideImage';
import CloseIcon from "@mui/icons-material/Close";
import {ImagePopUpModal} from "../components/image";
import {AIModelRelationHeaders, CameraHeaders} from "../util/getFields";
import {cameraFields} from "../util/modelFields";
import ErrorDisplay from "../components/errorDisplay";
import {grey} from "@mui/material/colors";
import WaitTimeStatus from "../components/waitTimeStatus";
import {EnumContext} from "../components/enumContext";
import {itronLabels, RenderChart} from "../components/chart";


export default function CameraDetail() {
    const token = Cookies.get('token');
    const canCreate = Cookies.get('can_create') === 'true';
    const {id} = useParams();
    const route = routes.camera;
    const {enums} = useContext(EnumContext);

    const [snackbarList, setSnackbarList] = useState([]);
    const [newData, setNewData] = useState({});
    const [noRecord, setNoRecord] = useState(false);
    const [noImage, setNoImage] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [refreshError, setRefreshError] = useState(false);
    const [deleteError, setDeleteError] = useState({error: false, message: null, status: null});
    const [modalOpen, setModalOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [popup, setPopup] = useState({
        'aimodel': false,
        'cameraproperty': false
    })

    const [autoRefresh, setAutoRefresh] = useState(sessionStorage.getItem('refresh') !== 'F');

    const {data: detail, loading, statusCode: getStatus, handleCall} = useGetDataById(token, route, id);
    const {statusCode: refreshStatus, handleCall: refreshCall} = useRefreshData(token, route, id);

    const navigate = useNavigate();
    const location = useLocation();

    const unitId = location.state?.unitId ? location.state.unitId : null;
    const unitSN = location.state?.unitSN ? location.state.unitSN : null;
    const page = location.state?.page ? location.state.page : null;

    const [newRecords, setNewRecords] = useState({
        filteredRecords: [],
        totalRecord: {},
        lastRecord: {},
    });
    const [timeRange, setTimeRange] = useState([new Date().getTime() - 30 * 60000, new Date().getTime()]);

    useEffect(() => {
        if (getStatus > 400) {
            navigate('/camera');
        }
    }, [getStatus]);

    const addSnackbar = (field, model, time) => {
        const id = new Date().getTime();
        setSnackbarList((prevSnackbars) => [
            ...prevSnackbars,
            {id, field, model, time},
        ]);
    };

    const handleClose = (id, event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarList((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.id !== id));
    };

    const handleAIModelClick = (aimodel) => {
        navigate(`/aimodel/${aimodel}`, {
            state: {
                from: location,
                unitSN: unitSN,
                unitId: unitId,
                cameraSN: newData.data.c_sn,
                cameraId: id
            }
        });
    }

    const handleModal = () => {
        setModalOpen((m) => !m);
    }

    const handleRefresh = async () => {
        try {
            setRefreshing(true);
            await refreshCall({body: {type: 'IMG'}});
            if (refreshStatus < 300) {
                await sleep(4000);
                // addSnackbar('Refreshing', 'Camera', '2 minutes')
                await handleCall();
            } else {
                setRefreshError(true);
            }
            setRefreshing(false);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        let interval;
        if (autoRefresh) {
            fetchDetail();
            interval = setInterval(() => {
                fetchDetail();
            }, 30000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [autoRefresh]);

    useEffect(() => {
        if (newData.records) {
            const filteredRecords = newData.records.filter(datum => new Date(datum.datetime).getTime() >= timeRange[0] && new Date(datum.datetime).getTime() <= timeRange[1]);
            setNewRecords({
                filteredRecords: filteredRecords,
                totalRecord: totalRecord(filteredRecords, timeRange),
                lastRecord: filteredRecords[filteredRecords.length - 1],
            })
        }
    }, [timeRange, newData.records]);

    useEffect(() => {

        let interval;
        if (autoRefresh) {
            handleRefresh();
            interval = setInterval(() => {
                handleRefresh();
            }, 120000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, []);

    const fetchDetail = async () => {
        try {
            await handleCall();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handlePopupClose = (key) => {
        setPopup({...popup, [key]: false});
    }

    const handleSetAutoRefresh = (e) => {
        e.preventDefault();
        setAutoRefresh(e.target.checked);
        sessionStorage.setItem('refresh', e.target.checked ? 'T' : 'F')
    }

    useEffect(() => {
        fetchDetail();
    }, []);

    useEffect(() => {
        if (JSON.stringify(detail) !== '{}') {
            // && filterTime(record, timeDiff)
            const updatedRecords = detail.records ? detail.records.filter((record) => (record.time)).sort((a, b) => new Date(a.time) - new Date(b.time)).map((record) => filterRecords(record, detail.data.c_sn)).filter(removeBadRecords) : [];
            const filteredRecords = updatedRecords.filter(datum => new Date(datum.datetime).getTime() >= timeRange[0] && new Date(datum.datetime).getTime() <= timeRange[1]);
            const updatedData = updateData(detail.data, CameraHeaders, 'c', enums);
            const updatedAIModel = updateData(detail.AIModel, AIModelRelationHeaders, 'am',);
            let updatedConfirmationMessage = updateConfirmationMessage(detail.ConfirmationMessage);
            const newTimeRange = [new Date(updatedRecords[updatedRecords.length - 1].datetime).getTime() - 30 * 60000, new Date(updatedRecords[updatedRecords.length - 1].datetime).getTime()];
            setTimeRange(newTimeRange);
            setNewData({
                ...detail,
                data: updatedData,
                records: updatedRecords,
                AIModel: updatedAIModel,
                ConfirmationMessage: updatedConfirmationMessage,
            });
            setNewRecords({
                filteredRecords: filteredRecords,
                totalRecord: totalRecord(filteredRecords, newTimeRange),
                lastRecord: filteredRecords[filteredRecords.length - 1],
            });
            (updatedRecords && updatedRecords.length > 0) ? setNoRecord(false) : setNoRecord(true);
            (detail.images && detail.images.length > 0 && detail.images[0].file) ? setNoImage(false) : setNoImage(true);
        }
    }, [detail]);

    if (loading || Object.keys(newData).length === 0) return <LoadingComponent/>

    return <Grid container spacing={1} alignItems="stretch" justifyContent="center"
                 sx={{margin: 'auto', mt: 1, width: '96%', ml: 6}}>
        <Grid item xs={12}>
            <Collapse in={refreshError}>
                <Alert severity="error"
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={() => {
                                   setRefreshError(false);
                               }}
                           >
                               <CloseIcon fontSize="inherit"/>
                           </IconButton>
                       }
                       sx={{mb: 2}}
                >
                    <AlertTitle>Error</AlertTitle>
                    Couldn't refresh at the time. Please try again later.
                </Alert>
            </Collapse>
            <ErrorDisplay
                fields={cameraFields}
                alert={deleteError.error}
                setAlert={(alert) => setDeleteError({...deleteError, error: alert})}
                response={deleteError.message}
                statusCode={deleteError.status}
            />
            <WaitTimeStatus
                snackbarList={snackbarList}
                handleClose={handleClose}
            />
        </Grid>
        <Grid item xs={12} lg={3.35} key="image">
            {noImage ?
                <Card key='no-image-card'
                      sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                    <CardHeader title="Image"/>
                    <CardContent>
                        <IconButton disableRipple>
                            <HideImageIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            Image not Available
                        </Typography>
                    </CardContent>
                </Card>
                : [
                    <Card key='image-card'
                          sx={{borderRadius: '16px', width: '100%', padding: 2, paddingBottom: 0}}>
                        <CardHeader title="Image"/>
                        <CardContent>
                            <Grid container
                                  spacing={1}
                                  sx={{margin: 'auto'}}
                                  alignItems="center"
                                  justifyContent="center"
                            >
                                <Grid item xs={3} key='refresh-key'>
                                    <Typography variant="body1" color={grey[700]}>
                                        Time Taken
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} key='refresh-value'>
                                    <Typography variant="body1">
                                        {newData.images[0] ? toHumanReadableTime(newData.images[0].time).datetimeStr : "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActionArea onClick={handleModal} sx={{mb: 2}}>
                            <CardMedia
                                component="img"
                                image={fileUrl(newData.images[0].file)}
                                alt={newData.images[0].time}
                            />
                        </CardActionArea>
                    </Card>,
                    <ImagePopUpModal
                        key='image-popup'
                        img={fileUrl(newData.images[0].file)}
                        open={modalOpen}
                        setOpen={setModalOpen}
                        currentPoints={detail.data.c_coordinates}
                        id={id}
                        addSnackbar={addSnackbar}
                        refresh={fetchDetail}
                    />
                ]
            }
        </Grid>
        <Grid item xs={12} lg={4.65} key="chart" sx={{minHeight: 400}}>
            <Card key='chart-card'
                  sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    title="Visualization"
                    action={<FormControlLabel
                        control={<Switch
                            color="success"
                        />}
                        checked={autoRefresh}
                        onChange={handleSetAutoRefresh}
                        label="Auto Refresh"
                        labelPlacement="start"
                        sx={{margin: 0}}
                    />}
                />
                <CardContent>
                    {noRecord ? <Typography variant="button">
                            No Data Found
                        </Typography>
                        : <RenderChart data={newData.records} timeRange={timeRange} setTimeRange={setTimeRange}/>}
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} lg={2}>
            <Grid container direction="column" spacing={2}>
                {newRecords.lastRecord && Object.entries(newRecords.lastRecord.results).map(([key, value]) => (
                    <Grid item xs={12} lg={6} key={key} sx={{height: 300}}>
                        <Card key={`${itronLabels[key]}-card`}
                              sx={{
                                  borderRadius: '16px',
                                  width: '100%',
                                  height: 300,
                                  padding: 2,
                                  paddingBottom: 0
                              }}>
                            <CardHeader
                                title={itronLabels[key]}
                                sx={{height: 160}}
                            />
                            <CardContent sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography variant="display">
                                    {value}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
        <Grid item xs={12} lg={2}>
            <Grid container direction="column" spacing={2}>
                {newRecords.totalRecord && Object.entries(newRecords.totalRecord).map(([key, value]) => (
                    <Grid item xs={12} lg={6} key={key} sx={{height: 300}}>
                        <Card key={`${itronLabels[key]}-card`}
                              sx={{
                                  borderRadius: '16px',
                                  width: '100%',
                                  height: 300,
                                  padding: 2,
                                  paddingBottom: 0
                              }}>
                            <CardHeader
                                title={`Total ${itronLabels[key]}`}
                                sx={{height: 160}}
                            />
                            <CardContent sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography variant="total">
                                    {value}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
}