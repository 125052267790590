export const isAboveTheBorder = ({x, y}, svg) => {
    const {width, height} = svg.viewBox.baseVal;
    const {x: offsetX, y: offsetY} = getViewboxOffset(svg);
    return x < -offsetX || x > width + offsetX || y < -offsetY || y > height + offsetY;
}

export function getViewboxOffset(svg) {
    const {width: rWidth, height: rHeight} = {
        width: svg.clientWidth,
        height: svg.clientHeight
    };
    const {width: vWidth, height: vHeight} = svg.viewBox.baseVal;
    const point = Object.assign(svg.createSVGPoint(), {
        x: rWidth,
        y: rHeight
    });
    const ctm = svg.getCTM();
    const {x, y} = point.matrixTransform(ctm.inverse());
    // only for preserveAspectRatio="xMidYMid meet" !!!
    return {
        x: x - vWidth,
        y: y - vHeight
    };
}

export function convertViewboxPointsToReal(points, svg, ratio) {
    const ctm = svg.getCTM();
    return points.map(({x, y}) => {
        const point = svg.createSVGPoint();
        point.x = x;
        point.y = y;
        const transformed = roundPointCoordinates(point.matrixTransform(ctm));
        return roundPointCoordinates({x: transformed.x / ratio.x, y: transformed.y / ratio.y});
    });
}

export function convertRealPointsToViewbox(points, svg, ratio) {
    const ctm = svg.getCTM().inverse();
    let clientPoints = [...points];
    if (ratio) {
        clientPoints = points.map(({x, y}) => ({x: x * ratio.x, y: y * ratio.y}));
    }

    return clientPoints.map(({x, y}) => {
        const point = svg.createSVGPoint();
        point.x = x;
        point.y = y;
        const transformed = point.matrixTransform(ctm);
        return roundPointCoordinates(transformed, 1e3);
    });
}

export const roundPointCoordinates = ({x, y}, p = 1) => ({
    x: Math.round((x + Number.EPSILON) * p) / p,
    y: Math.round((y + Number.EPSILON) * p) / p
});
