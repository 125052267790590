import {
    Card,
    CardHeader,
    FormControlLabel,
    Grid,
    Modal,
    Switch,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";

import ReactLassoSelect from "react-lasso-select";
import Button from "@mui/material/Button";
import {routes, usePatchData} from "../routes/api";
import Cookies from "js-cookie";
import ErrorDisplay from "./errorDisplay";
import {cameraFields} from "../util/modelFields";
import LineSelect from "../lineSelect/lineSelect";

const areArraysEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
};


export function ImagePopUpModal({img, open, setOpen, id, currentPoints, addSnackbar, refresh}) {
    const token = Cookies.get('token');
    const route = routes.camera;
    const canCreate = Cookies.get('can_create') === 'true';

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [polyPoints, setPolyPoints] = useState((currentPoints && currentPoints.length > 2) ? currentPoints : []);
    const [linePoints, setLinePoints] = useState((currentPoints && currentPoints.length === 2) ? currentPoints : []);
    const [mode, setMode] = useState(currentPoints && currentPoints.length === 2 ? 'line' : 'polygon');
    const [lassoClosed, setLassoClosed] = useState(false);
    const [lineFinished, setLineFinished] = useState(false);
    const [alert, setAlert] = useState(false);
    const [changed, setChanged] = useState(false);

    const {
        data: patchResp,
        statusCode: patchStatus,
        handleCall: handlePatch
    } = usePatchData(token, route, id);

    const handleClose = () => {
        setOpen(false);
        setLassoClosed(false);
        setLineFinished(false);
        setMode(currentPoints && currentPoints.length === 2 ? 'line' : 'polygon');

        if (currentPoints && currentPoints.length > 2) {
            setPolyPoints(currentPoints);
            setLinePoints([]);
        } else if (currentPoints && currentPoints.length === 2) {
            setLinePoints(currentPoints);
            setPolyPoints([]);
        }
    }

    const handleModeChange = () => {
        setMode((m) => (m === 'line' ? 'polygon' : 'line'));
    }

    const handleSubmit = () => {
        if (mode === 'line') {
            handlePatch({body: {c_coordinates: linePoints}});
        } else if (mode === 'polygon') {
            handlePatch({body: {c_coordinates: polyPoints}});
        }

        if (refresh) {
            refresh();
        }
    }

    useEffect(() => {
        if (patchStatus >= 200 && patchStatus < 300) {
            if (addSnackbar) {
                addSnackbar('Updating ROI', 'Image', '2 minutes');
            }
            setAlert(false);
            setOpen(false);
            setLineFinished(false);
            setLassoClosed(false);
        } else if (patchStatus > 300) {
            setAlert(true);
        }
    }, [patchStatus, patchResp]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '95vh',
                maxWidth: isSmallScreen ? '85vw' : '63vw',
                height: 'auto',
                width: 'auto',
                overflow: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                padding: 1,
                borderRadius: '16px'
            }}>
                <CardHeader title="Select ROI"/>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <ErrorDisplay
                            alert={alert}
                            setAlert={setAlert}
                            response={patchResp}
                            fields={cameraFields}
                            statusCode={patchStatus}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1}}>
                        <Typography variant="body1" sx={{marginRight: 1}}>
                            Line
                        </Typography>
                        <FormControlLabel
                            control={<Switch checked={mode === 'polygon'} onChange={handleModeChange}/>}
                            label=""
                            sx={{margin: 0}}
                        />
                        <Typography variant="body1" sx={{marginLeft: 1}}>
                            Polygon
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {mode === 'polygon' &&
                            <ReactLassoSelect
                                value={polyPoints}
                                src={img}
                                disabled={!canCreate}
                                onChange={(value) => setPolyPoints(value)}
                                onComplete={() => setLassoClosed(true)}
                                style={{width: "100%", maxWidth: "1200px"}}
                                imageStyle={{width: "100%", maxWidth: "1200px"}}
                            />
                        }
                        {mode === 'line' &&
                            <LineSelect
                                src={img}
                                alt={'image'}
                                value={linePoints}
                                key='line-select'
                                onChange={(value) => setLinePoints(value)}
                                onFinish={() => setLineFinished(true)}
                                style={{width: "100%", maxWidth: "1200px"}}
                                imageStyle={{width: "100%", maxWidth: "1200px"}}

                            />
                        }
                    </Grid>
                    <Grid item xs={10}>
                        {canCreate &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                sx={{mt: 2, mb: 2}}
                                disabled={!(mode === 'polygon' && lassoClosed && !areArraysEqual(currentPoints, polyPoints)) &&
                                    !(mode === 'line' && lineFinished && !areArraysEqual(currentPoints, linePoints))}

                            >
                                Submit
                            </Button>}
                    </Grid>
                </Grid>
            </Card>
        </Modal>
    )
}