export const CameraHeaders = [
    'c_sn',
    'c_sensor',
    'c_active',
    'c_part',
    'c_status',
    'c_error',
    'c_timeAdded',
    'c_order',
    'last_update_time',
    'id',
]

export const CameraListHeaders = [
    'c_sn',
    'c_sensor',
    'c_active',
    'c_part',
    'c_status',
    'c_error',
    'c_model',
    'c_timeAdded',
    'last_update_time',
    'id',
]

export const UnitHeaders = [
    'u_sn',
    'u_active',
    'u_part',
    'u_status',
    'u_error',
    'u_cam_error',
    'u_latitude',
    'u_longitude',
    'u_temp',
    'u_hdd',
    'u_pole',
    'u_notes',
    'u_timeAdded',
    'u_timeLastSynced',
    'id',
]

export const UnitListHeaders = [
    'u_sn',
    'u_active',
    'u_camera',
    'u_status',
    'u_wifi',
    'u_error',
    'u_cam_error',
    'u_part',
    'u_pole',
    'u_temp',
    'u_hdd',
    'u_firmware',
    'u_application',
    'u_timeLastSynced',
    'u_latitude',
    'u_longitude',
    'u_timeAdded',
    'id',
]

export const UnitMapHeaders = [
    'u_sn',
    'u_active',
    'u_camera',
    'u_status',
    'u_wifi',
    'u_error',
    'u_cam_error',
    'u_firmware',
    'u_application',
    'u_timeLastSynced',
    'id',
]

export const FirmwareHeaders = [
    'f_name',
    'f_version',
    'f_content',
    'f_md5',
    'f_buildDate',
    'f_feature',
    'f_timeAdded',
    'id',
]

export const FirmwareRelationHeaders = [
    'f_name',
    'f_version',
    'f_content',
    'f_md5',
    'f_buildDate',
    'id',
]

export const AIModelHeaders = [
    'am_name',
    'am_version',
    'am_content',
    'am_md5',
    'am_buildDate',
    'am_feature',
    'am_timeAdded',
    'id',
]

export const AIModelRelationHeaders = [
    'am_name',
    'am_version',
    'am_content',
    'am_md5',
    'am_buildDate',
    'id',
]

export const ApplicationHeaders = [
    'app_name',
    'app_version',
    'app_content',
    'app_md5',
    'app_buildDate',
    'app_feature',
    'app_timeAdded',
    'id',
]

export const ApplicationRelationHeaders = [
    'app_name',
    'app_version',
    'app_content',
    'app_md5',
    'app_buildDate',
    'id',
]

export const UserHeaders = [
    'username',
    'first_name',
    'last_name',
    'email',
    'is_active',
    'is_staff',
    'id',
]

export const AccountHeaders = [
    'a_type',
    'a_phone',
]

export const AccountListHeaders = [
    'first_name',
    'last_name',
    'username',
    'a_type',
    'email',
    'a_phone',
    'is_active',
    'is_staff',
    'id',
]

export const OrganizationHeaders = [
    'o_name',
    'o_alias',
    'o_description',
    'o_usedStorage',
    'o_maxStorage',
    'o_billing',
    'id'
]