import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {Fragment, useEffect, useState} from "react";
import {routes, usePatchData} from "../routes/api";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import ErrorDisplay from "./errorDisplay";
import {userFields} from "../util/modelFields";
import {grey} from "@mui/material/colors";
import {ContentEditField, ContentField} from "./editField";
import {getRequiredFields, isDict} from "../util/util";


export default function ContentDetail({alias, id, data, edit, setEdit, refresh, fields}) {
    const token = Cookies.get('token');
    const route = routes[alias];
    const editableFields = Object.values(fields.fields).reduce((acc, key) => {
        if (key.edit) {
            return [...acc, key.alias];
        } else {
            return acc;
        }
    }, []);
    const [editedData, setEditedData] = useState(
            Object.keys(data).reduce((acc, key) => (
                editableFields.includes(key) ? {...acc, [key]: data[key]} : acc), {})
        )
    ;
    const requiredFields = getRequiredFields(fields);
    const [fieldChecks, setFieldChecks] = useState({});
    const [errorFields, setErrorFields] = useState([]);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState(false);

    const {
        data: patchResp,
        statusCode: patchStatus,
        handleCall: handlePatch
    } = usePatchData(token, route, id);

    const handleEdit = (e, regexp) => {
        if (e.target.value) {
            setEditedData({
                ...editedData,
                [e.target.name]: e.target.value
            });
        } else {
            setEditedData({
                ...editedData,
                [e.target.name]: ''
            });
        }

        if (regexp) {
            setFieldChecks({
                ...fieldChecks,
                [e.target.name]: !regexp.test(e.target.value)
            });
        }

        setErrorFields(errorFields.filter(item => item !== e.target.name));
    }
    const handleEditUser = (e, regexp) => {
        setEditedData({
            ...editedData,
            a_user: {
                ...editedData.a_user,
                [e.target.name]: e.target.value
            }
        });

        if (regexp) {
            setFieldChecks({
                ...fieldChecks,
                [e.target.name]: !regexp.test(e.target.value)
            });
        }

        setErrorFields(errorFields.filter(item => item !== e.target.name));
    }

    const handleEditBool = (e) => {
        setEditedData({
            ...editedData,
            [e.target.name]: e.target.checked
        })
    }

    const handleEditDate = (key, value) => {
        setEditedData({
            ...editedData,
            [key]: value.format('YYYY-MM-DD')
        })
    }

    const handleEditUserBool = (e) => {
        setEditedData({
            ...editedData,
            a_user: {
                ...editedData.a_user,
                [e.target.name]: e.target.checked
            }
        })
    }

    const handleSubmit = () => {
        handlePatch({body: editedData});
    };

    useEffect(() => {
        let currentError = requiredFields.reduce((acc, item, _) => (acc || (!editedData[item])), false);
        currentError = Object.values(fieldChecks).reduce((acc, item, _) => (acc || item), currentError);
        setError(currentError || errorFields.length !== 0);
    }, [editedData, fieldChecks]);

    useEffect(() => {
        if (patchStatus >= 200 && patchStatus < 300) {
            setAlert(false);
            setEdit(false);
            if (refresh) {
                refresh();
            }
        } else if (patchStatus > 300) {
            setAlert(true);
            const errors = isDict(patchResp) ? Object.keys(patchResp) : [];
            const userErrors = errors.includes('a_user') && isDict(patchResp.a_user) ? Object.keys(patchResp.a_user) : [];
            setErrorFields([...errors, ...userErrors].filter(item => item !== 'a_user'));
        }
    }, [patchStatus, patchResp]);

    return (
        <Grid container
              spacing={1}
              sx={{margin: 'auto', mb: 2}}
              alignItems="center"
              justifyContent="center"
        >
            <Grid item key='error-display' xs={12}>
                <ErrorDisplay
                    alert={alert}
                    setAlert={setAlert}
                    response={patchResp}
                    fields={fields}
                    statusCode={patchStatus}
                    account
                />
            </Grid>
            {Object.keys(data).includes('a_user') && Object.entries(data.a_user).map(([key, value]) => (
                key !== 'id' && <Fragment key={`${key}-group`}>
                    <Grid item xs={5} key={key} sx={{mb: 1}}>
                        <Typography variant="body1" color={grey[700]}>
                            {userFields.fields[key].display || key}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                        {(edit && editableFields.includes(key)) ?
                            <ContentEditField
                                field={userFields.fields[key]}
                                editedData={editedData.a_user}
                                alias={key}
                                error={errorFields.includes(key)}
                                onChange={handleEditUser}
                                onBoolChange={handleEditUserBool}
                                regexCheck={fieldChecks}
                            /> :
                            <ContentField
                                field={userFields.fields[key]}
                                alias={key}
                                value={value}
                            />
                        }
                    </Grid>
                </Fragment>
            ))}
            {Object.entries(data).filter(([key1, _]) => key1 !== 'a_user').map(([key, value]) => (
                key !== 'id' && <Fragment key={`${key}-group`}>
                    <Grid item xs={5} key={key} sx={{mb: 1}}>
                        <Typography variant="body1" color={grey[700]}>
                            {fields.fields[key].display || key}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                        {(edit && editableFields.includes(key)) ?
                            <ContentEditField
                                field={fields.fields[key]}
                                editedData={editedData}
                                alias={key}
                                error={errorFields.includes(key)}
                                onChange={handleEdit}
                                onDateChange={handleEditDate}
                                onBoolChange={handleEditBool}
                                onEnumChange={handleEdit}
                                regexCheck={fieldChecks}
                            /> :
                            <ContentField
                                field={fields.fields[key]}
                                alias={key}
                                value={value}
                            />
                        }
                    </Grid>
                </Fragment>
            ))}
            {
                edit && <Grid item xs={12} key='submit'>
                    <Button component="label"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={error}
                            sx={{margin: 2}}>
                        Submit
                    </Button>
                </Grid>
            }
        </Grid>
    )
}