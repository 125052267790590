import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import React, {useEffect, useState} from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "js-cookie";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import {LoadingComponent} from "../util/loading";
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {routes, usePostFile} from "../routes/api";
import {fileModel, isDict, VisuallyHiddenInput} from "../util/util";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import Properties from "../components/property";
import RelationView from "../components/relationView";
import {Divider, IconButton, InputAdornment} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorDisplay from "../components/errorDisplay";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";


export default function FileCreate({alias, setId, fields, navigation, setClose}) {
    const token = Cookies.get('token');
    const canCreate = Cookies.get('can_create') === 'true';
    const route = routes[alias];
    const fileNameRegexp = /^[\w-]{1,50}$/;

    const [fileProperties, setFileProperties] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [formData, setFormData] = useState({
        version: '1.0.0',
        feature: '',
        name: '',
        builtDate: dayjs(new Date()),
    });
    const [error, setError] = useState(false);
    const [propertyError, setPropertyError] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const {
        data: postResp,
        statusCode: postStatus,
        handleCall: postFile
    } = usePostFile(token, route);

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from ? location.state.from : {pathname: `/${alias}`};


    useEffect(() => {
        if (postStatus >= 200 && postStatus < 300 && JSON.stringify(postResp) !== '{}') {
            setAlert(false);
            if (setId) {
                setId(postResp.id)
            }
            if (navigation) {
                navigate(from);
            }
            if (setClose) {
                setClose();
            }
        } else if (postStatus > 300) {
            setAlert(true);
            setErrorFields(isDict(postResp) ? Object.keys(postResp) : []);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [postStatus, postResp]);

    useEffect(() => {
        const fileNameError = !fileNameRegexp.test(formData.name);
        setError(['version', 'name', 'builtDate'].reduce((acc, item, _) => (acc || (!formData[item])), false) || propertyError || fileNameError);
    }, [formData, propertyError])

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        const baseName = e.target.files[0].name.split('.')[0];
        setFormData({...formData, name: baseName})
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setErrorFields(errorFields.filter(item => item !== e.target.name));
    }

    const handleRelationChange = (newValue, key, multiple, limit) => {
        if (multiple) {
            if (!limit || !newValue || newValue.length <= limit) {
                setFormData({
                    ...formData,
                    [key]: newValue.map((item) => item[0])
                });
            }
        } else if (!newValue) {
            const tempForm = {...formData};
            delete tempForm[key];
            setFormData(tempForm);
        } else {
            setFormData({
                ...formData,
                [key]: newValue[0]
            });
        }
    }

    const handleNew = (value, key, multiple, limit) => {
        if (!multiple) {
            setFormData({
                ...formData,
                [key]: value
            });
        } else {
            if (formData[key]) {
                if (formData[key].length < limit) {
                    setFormData({
                        ...formData,
                        [key]: [...formData[key], value]
                    });
                }
            } else {
                setFormData({
                    ...formData,
                    [key]: [value]
                });
            }
        }
    }

    const handleDateChange = (value) => {
        setFormData({
            ...formData,
            builtDate: value
        })
    }
    const handleUpload = async () => {
        setLoading(true);
        const finalForm = new FormData();
        const pre = fileModel[alias].prefix;
        finalForm.append('file', selectedFile);
        finalForm.append(`${pre}_content`, selectedFile, selectedFile.name);
        finalForm.append(`${pre}_name`, formData.name);
        finalForm.append(`${pre}_version`, formData.version);
        finalForm.append(`${pre}_feature`, formData.feature);
        finalForm.append(`${pre}_buildDate`, formData.builtDate.format('YYYY-MM-DD'));
        if (formData[`${pre}_property`]) {
            finalForm.append(`${pre}_property`, formData[`${pre}_property`])
        }
        finalForm.append('new_property', JSON.stringify(Object.values(fileProperties)))
        await postFile({
            body: finalForm
        })
        setLoading(false);
    }

    if (!canCreate) return navigate(from);
    if (loading) return <LoadingComponent/>;

    return <Grid container alignItems="baseline" justifyContent="flex-start" spacing={2}
                 sx={{margin: 'auto', mt: 5, width: '80%'}}>
        <Grid item xs={12}>
            <ErrorDisplay
                alert={alert}
                setAlert={setAlert}
                fields={fields}
                response={postResp}
                statusCode={postStatus}
            />
        </Grid>
        {navigation && <Grid item xs={12}>
            <NavigateBreadcrumbs alias={alias} display={fileModel[alias].display} create/>
        </Grid>}
        <Grid item xs={12} sx={{mt: 5, mb: 1}}>
            <Typography variant='h4'>New {fileModel[alias].display}</Typography>
        </Grid>
        <Grid item xs={12} sx={{mb: 1}}>
            <Button component="label" variant="contained"
                    startIcon={<AddIcon/>}>
                File
                <VisuallyHiddenInput type="file" onChange={handleFileChange}/>
            </Button>
            {selectedFile &&
                <Typography variant="overline" sx={{margin: 2}}>
                    Uploaded File: {selectedFile.name} - {selectedFile.size} bytes
                </Typography>
            }
        </Grid>
        <Grid item xs={4}>
            <TextField
                fullWidth
                required
                id="name"
                name="name"
                label="Name"
                onChange={handleChange}
                disabled={!selectedFile}
                error={Boolean(selectedFile) && (!formData.name || !fileNameRegexp.test(formData.name))}
                value={formData.name}
                helperText={!fileNameRegexp.test(formData.name) && ("Enter letters, numbers, underscores or hyphens only. No spaces")}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <Tooltip title="Enter letters, numbers, underscores or hyphens only. No spaces" arrow>
                                <IconButton size='0' disableRipple sx={{width: 30}}><InfoOutlinedIcon/></IconButton>
                            </Tooltip>
                        </InputAdornment>
                }}
            />
        </Grid>
        <Grid item xs={4}>
            <TextField
                fullWidth
                required
                id="version"
                name="version"
                label="Version"
                type="text"
                // inputProps= {{step: 0.1, min: 0.1, max: 999.9}}
                onChange={handleChange}
                disabled={!selectedFile}
                value={formData.version}
            />
        </Grid>
        <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    fullWidth
                    id="date"
                    name="date"
                    label="Built Date"
                    onChange={handleDateChange}
                    disabled={!selectedFile}
                    value={formData.builtDate}
                />
            </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
            <TextField
                multiline
                fullWidth
                id="feature"
                name="feature"
                label={`${fileModel[alias].display} Features`}
                onChange={handleChange}
                value={formData.feature}
                rows={4}
                disabled={!selectedFile}
                sx={{width: '75ch'}}
            />
        </Grid>
        <Grid item xs={12} sx={{mt: 3}}>
            <Typography variant='h5'>Additional Information</Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={12}>
            <RelationView
                alias={alias}
                handleChange={handleRelationChange}
                handleNew={handleNew}
                currentValue={formData}
            />
        </Grid>
        <Grid item xs={12}>
            <Properties
                display={fileModel[alias].display}
                setData={setFileProperties}
                data={fileProperties}
                setError={setPropertyError}
                prefix={fileModel[alias].propertyPrefix}
            />
        </Grid>
        <Grid item xs={12}>
            <Button component="label"
                    variant="contained"
                    startIcon={<FileUploadIcon/>}
                    onClick={handleUpload}
                    disabled={!selectedFile || error}
                    sx={{mt: 2}}
            >
                Upload
            </Button>
        </Grid>
    </Grid>
}