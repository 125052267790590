import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import {useTheme} from "@mui/material";

export const LoadingComponent = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 20
            }}
        >
            <CircularProgress/>
        </Box>
    );
};

export const NoDataComponent = ({message = 'No data available'}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="200px"
        >
            <ReportOutlinedIcon style={{fontSize: 45, marginBottom: theme.spacing(2), color: 'primary'}}/>
            <Typography variant="h6">{message}</Typography>
        </Box>
    );
};