import {Dialog, DialogContent, DialogTitle} from "@mui/material";


export default function PopupDialog({open, display, content, handleClose}) {
    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="popup-dialog-title"
        aria-describedby="popup-dialog-description"
        fullWidth
        maxWidth='lg'
    >
        {display && <DialogTitle>
            {display}
        </DialogTitle>}
        <DialogContent
            sx={{
                minWidth: '270px',
                minHeight: '50px',
                mt: 2,
                overflow: 'visible'
            }}
        >
            {content}
        </DialogContent>
    </Dialog>
}
